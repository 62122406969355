import * as actionType from "./reports.type";

const initialState = {
  loading: false,
  reports: []

};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
      case actionType.SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
   
      case actionType.SET_LOADING:
        return {
          ...state,
          loading: !state.loading,
        };
     
  }
  return state;
};
