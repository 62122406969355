import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '../../components/UI/Modal.component'
import { categorySelector, categoryMessageSelector, categoryUpdateSelector, categoryListSelector, categoryLoadingSelector } from '../../redux/category/category.selector'
import { addCategory, addingCategoryAction, updateCategoryAction } from '../../redux/category/category.action'
import { Spinner } from '../../components/UI/Spinner.component'
import { Alert } from '../../components/UI/Alert.component'

export const AddCategory = () => {
    const [category, setCategory] = useState('')
    const Dispatch = useDispatch()
    const isOpenCategory = useSelector((state) => categorySelector(state))
    const categoryMSG = useSelector((state) => categoryMessageSelector(state))
    const isUpdate = useSelector((state) => categoryUpdateSelector(state))
    const categoryList = useSelector((state) => categoryListSelector(state))
    const Loading = useSelector((state) => categoryLoadingSelector(state))

    const AddCatHandler = () => {
        if (!isUpdate) {
            Dispatch(addingCategoryAction({ cat_name: category, is_enable: true }))
        } else {
            Dispatch(updateCategoryAction({ cat_name: category, is_enable: true }, isUpdate))
        }

    }

    useEffect(() => {

        if (isUpdate) {
            let cat_name = categoryList.find((list) => list.cat_id == isUpdate).cat_name
            setCategory(cat_name)
        } else {
           
        }
    }, [isUpdate])

    useEffect(() => {
       
    },[])

    const HandleClose = () => {
        Dispatch({ type: 'ADD_CLEAR' })
        Dispatch(addCategory())
        setCategory('')
    }



    return (
        !categoryMSG ?
            <>
                <Modal isopen={isOpenCategory} title="Add Category" btnDisabled={true} handleClose={() => Dispatch(addCategory())} submit={AddCatHandler} >
                    <div>
                        <div className="mb-3">
                            <label for="question" className="form-label">Category Name</label>
                            <input type="text" className="form-control" id="question"
                                placeholder="Write Category" value={category} onChange={(e) => setCategory(e.target.value)} required/>
                        </div>
                    </div>
                </Modal>
                <Spinner  open={Loading}/>
            </>
            :
            <Alert
                title={categoryMSG}
                isOpen={isOpenCategory}
                close={HandleClose}
            />
    )
}