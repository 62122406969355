import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";
import {setUsername,setPassword,setToken,login,setLoginFailed} from "../../redux/user/user.action"
import {useDispatch,useSelector} from 'react-redux'
import { Alert } from '../../components/UI/Alert.component'


export const Login = () => {

  const dispatch = useDispatch()
  const {username, password, token, loginFailed, loading} = useSelector(state => state.user)

  console.log('UserName', username);
  console.log('Password', password);

  const handleSubmit = (e) =>{
    e.preventDefault()
    dispatch(login(username,password))
  }

  return (
    <div className="login-container">
      <form className="login" onSubmit={handleSubmit} >
        <h2>Login to your account</h2>
        <label>Username</label>
        <div>
        <div class="input-group mb-3">
          {/* <label>Username</label>               */}
          <span class="input-group-text" id="basic-addon1">
            <FaUserAlt className="icon" />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={username}
            onChange={(e) => dispatch(setUsername(e.target.value))}
            required
          ></input>
         
        </div>
        {/* <span className="invalid">* Username is required</span> */}
        </div>
        <label>Password</label>
        <div class="input-group mb-1">
          
          <span class="input-group-text" id="basic-addon1">
            <BsLockFill className="icon" />
          </span>
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={password}
            onChange={(e) => dispatch(setPassword(e.target.value))}
            required
          ></input>
          
        </div>
        {/* <span className="invalid invalid-password">* Password is required</span> */}
      
        <div>
          <button class="form-control" type="submit" >{loading?'Logging in...' :'Log In'}</button>
        </div>
      </form>
      <Alert
                title={loginFailed}
                isOpen={loginFailed}
                close={() => dispatch(setLoginFailed()) }
              
            />
    </div>
  );
};
