import * as actionType from "./user.type";

const initialState = {
  username: "",
  password: "",
  token: "",
  userType: "",
  loginFailed: false,
  loading: false

};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case actionType.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case actionType.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        usertype : action.usertype
      };

      
    case actionType.SET_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: action.payload,
      };

      case actionType.SET_LOADING:
        return {
          ...state,
          loading : !state.loading,
        };
  }
  return state;
};
