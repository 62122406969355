import * as actionType from './question.type'
import axios from 'axios'
export const addQuestion = () => ({
    type: actionType.ADD_QUESTION
})

export const bulkUpload = () => ({
    type: actionType.BULK_UPLOAD
})

export const updateQuestion = (id) => ({
    type: actionType.UPDATE_QUESTION,
    payload: id
})

export const questionLoading = () => ({
    type: actionType.QUESTION_SAVING_START
})
export const questionAdded = (data) => ({
    type: actionType.QUESTION_SAVING_SUCCESS,
    payload: data
})
export const questionList = (data) => ({
    type: actionType.QUESTION_GETING_SUCCESS,
    payload: data
})
export const questionFailed = (error) => ({
    type: actionType.QUESTION_SAVING_FAILED,
    payload: error
})

export const loadingFile = () => ({
    type: actionType.LOADING_FILE,
})
export const isloadingFileSuccess = (data) => ({
    type: actionType.LOADING_FILE_SUCCESS,
    payload: data
})
export const loadingFileFailed = (error) => ({
    type: actionType.LOADING_FILE_FAILED,
    payload: error
})

export const getQuestionLoading = () => ({
    type: actionType.GET_QUESTION_LOADING
})

export const addingQuestionAction = (data) => {
    const token = localStorage.getItem('token')
    console.log(data)
    return dispatch => {
        dispatch(questionLoading())
        axios.post('/question', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(questionAdded('Question saved Successfully'))
                dispatch(getingQuestionAction(data.m_id))
            }).catch((error) => {
                console.log(error)
                dispatch(questionFailed())
            })
    }

}

export const updateQuestionAction = (data, url) => {
    const token = localStorage.getItem('token')
    const { q_id, m_id, lang } = url
    console.log(url)
    return dispatch => {
        dispatch(questionLoading())
        axios.patch(`/question/${q_id}/${m_id}/${lang}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                dispatch(questionAdded('Question saved Successfully'))
                dispatch(getingQuestionAction(data.m_id))
            }).catch((error) => {
                console.log(error.response)
                dispatch(questionFailed(error.response.data))
            })
    }

}

export const switchQuestionAction = (data, id) => {
    const token = localStorage.getItem('token')
    console.log(data)
    return dispatch => {
        dispatch(questionLoading())
        axios.patch('/question/' + id, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                dispatch(questionAdded())
                dispatch(getingQuestionAction())
            }).catch((error) => {
                console.log(error)
                dispatch(questionFailed())
            })
    }

}

export const deleteQuestionAction = (url) => {
    const token = localStorage.getItem('token')

    const { q_id, m_id, lang } = url
    return dispatch => {
        dispatch(questionLoading())
        axios.delete(`/question/${q_id}/${m_id}/${lang}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                dispatch(questionAdded())
                dispatch(getingQuestionAction(m_id))
            }).catch((error) => {
                console.log(error)
                dispatch(questionFailed())
            })
    }

}


export const getingQuestionAction = (id) => {
    const token = localStorage.getItem('token')
    return dispatch => {
        dispatch(getQuestionLoading())
        axios.get('/question/' + id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(questionList(resp.data))
                dispatch(getQuestionLoading())
            }).catch((error) => {
                console.log(error.response)
                dispatch(questionFailed())
                dispatch(getQuestionLoading())
            })
    }

}

export const getingQuestionByIDAction = (id) => {
    const token = localStorage.getItem('token')
    return dispatch => {
        dispatch(questionLoading())
        axios.get('/question/' + id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(questionList(resp.data))
            }).catch((error) => {
                console.log(error.response)
                dispatch(questionFailed())
            })
    }

}

export const uploadFile = (data,file) => {
    const token = localStorage.getItem('token')
    let fd = new FormData();

    // file.type = "text/csv"
    // fd.set(file.type,'text/csv')
    fd.append("question", file);
    console.log("FILE",file.type);
   
    return dispatch => {
        dispatch(loadingFile());
        axios.post(`/questionBluck/${data.m_id}/${data.lang}/${data.m_type}`,fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(isloadingFileSuccess('File Uploaded Successfully'))
            }).catch((error) => {
                console.log(error.response.data.error)
                dispatch(loadingFileFailed(error.response.data.error))
                
            })
    }

}