import * as actionType from "./winners.type";

const initialState = {
  winners: [],
  winnersLoading: false,
  winnersLoadingFailed: false,
  winnersLoadingSuccess: false

};

export const winnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GETTING_WINNERS:
      return {
        ...state,
       winnersLoading: true,
       winnersLoadingFailed: false,
       winnersLoadingSuccess: false
      };

      case actionType.GET_WINNERS_SUCCESS:
        return {
          ...state,
         winnersLoading: false,
         winnersLoadingFailed: false,
         winnersLoadingSuccess: true,
         winners: action.payload
        };

        case actionType.GET_WINNERS_ERROR:
            return {
              ...state,
             winnersLoading: false,
             winnersLoadingFailed: true,
             winnersLoadingSuccess: false
            };
    
  }
  return state;
};
