import React, { useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Header } from './components/Header/header'
import { AddQuestion } from './containers/newQuestion/addQuestion.component'
import { AddCategory } from './containers/category/category.component'
import { BulkUpload } from './containers/bulkUpload/bulkUpload'
import { getingCategoryAction } from './redux/category/category.action'
import { setToken } from "./redux/user/user.action"
import { useDispatch, useSelector } from 'react-redux'
import { Category } from './pages/Category/caytegory.page'
import { Questions } from './pages/Questions/questions.page'
import { Withdraw } from './pages/Withdraw/withdraw.page'
import {Winners} from './pages/Winners/winners.page'
import { Login } from './pages/Login/login.page'
import { AuthChecking } from './redux/user/user.action'
import axios from 'axios'
import Report from './pages/Report/report.page';

axios.defaults.baseURL = 'https://api-camroon-game.gamesawaari.com/';
// axios.defaults.baseURL = 'http://localhost:8000';

// axios.defaults.headers.common['Authorization'] = ;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if (error.response.status == 401) {
    localStorage.clear()
    window.location.reload(false)
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


function App() {
  const Dispatch = useDispatch()
  const { token, usertype } = useSelector(state => state.user)

  useEffect(() => {
    if (token) {
      Dispatch(getingCategoryAction())
    }
  }, [token])

  useEffect(() => {
    Dispatch(AuthChecking())
  }, [])

  if (token !== '') {
    return (
      <Router>
        <Header />
        <div className="container-fluid">
          {usertype == 1 &&
            <>
              <Route exact path="/" component={Category} />
              <Route path="/questions/:id" component={Questions} />
            </>         
          }
          {console.log('USer Type',usertype)}
          {usertype == 0 && <>
            <Route exact path="/" component={Withdraw} />
            <Route exact path="/winners" component={Winners} />
            </>

          }
          {usertype == 2 && 
             <Route exact path="/" component={Report} />

          }
         
        </div>
        <AddQuestion />
        <AddCategory />
        <BulkUpload />
      </Router>
    );
  }
  else {
    return <Login />
  }

}

export default App;
