import * as actionType from './user.points.type'
import axios from 'axios'


export const userPointLoading = () => ({
    type: actionType.PONTS_GETING_START
})

export const userPointList = (data) => ({
    type: actionType.PONTS_GETING_SUCCESS,
    payload: data
})

export const userWithdrawSuccess = (data) => ({
    type: actionType.WITHDRAW_SUCCESS,
    payload: data
})

export const pontsClear = () => ({
    type: 'PONTS_CLEAR',
})


export const setpontsData = (data) => ({
    type: 'SET_PONTS_DATA',
    payload: data
})



export const userPointFailed = (data) => ({
    type: actionType.PONTS_GETING_FAILED,
    payload: data
})

export const setLockWinnerLoading = () => ({
    type: 'LOCK_WINNER_LOADING',
    // payload: data
})

export const setLockWinnerSuccess = (data) => ({
    type: 'LOCK_WINNER_SUCCESS',
    payload: data
})



export const getingUserPointAction = (data) => {
    const token = localStorage.getItem('token')
    return dispatch => {
        dispatch(userPointLoading())
        axios.post('/userpoints', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)                
                dispatch(userPointList(resp.data))
           

            }).catch((error) => {
                console.log(error.response.data)
                dispatch(userPointFailed(error.response.data))
            })
    }

}


export const lockWinner = (data) => {
    const token = localStorage.getItem('token')
    
    return dispatch => {
        // dispatch(userPointLoading())
        dispatch(setLockWinnerSuccess(false))
        dispatch(setLockWinnerLoading())
        console.log('DATATA',data);
        axios.post('/lockWinner', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)                
                // dispatch(userPointList(resp.data))
                dispatch(setLockWinnerLoading())
                dispatch(setLockWinnerSuccess(resp.data))
                dispatch(setpontsData([]))

            }).catch((error) => {
                console.log(error.response.data)
                // dispatch(userPointFailed(error.response.data))
                dispatch(setLockWinnerLoading())
            })
    }

}



// export const withdrawAction = (data) => {
//     alert('ok')
//     const token = localStorage.getItem('token')
//     return dispatch => {
//         axios.post('/withdraw', data, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         })
//             .then((resp) => {
//                 console.log(resp)
//                 if(resp.status == 400){
//                     dispatch(userPointFailed(resp.data))
//                 }
//                 dispatch(userWithdrawSuccess(resp.data))
//             }).catch((error) => {
//                 console.log(error.response)
//                 dispatch(userPointFailed())
//             })
//     }

// }