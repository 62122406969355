import * as actionType from "./user.points.type";

const initialState = {
  isOpen: false,
  loading: false,
  data: [],
  error: null,
  status: null,
  isUpdate: null,
  withdrawSuccess: false,
  withdrawFailed: false,
  lockWinnerLoading: false,
  lockWinnerSuccess: false,

};

export const pointReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PONTS_GETING_START:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case actionType.PONTS_GETING_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionType.PONTS_GETING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case actionType.WITHDRAW_SUCCESS:
      return {
        ...state,
        // data: action.payload,
        withdrawSuccess: false,
      };

      case 'SET_PONTS_DATA':
      return {
        ...state,
        data: action.payload,
        // withdrawSuccess: false,
      };

      case 'LOCK_WINNER_LOADING':
        return {
          ...state,
         lockWinnerLoading: !state.lockWinnerLoading
        };

        case 'LOCK_WINNER_SUCCESS':
          return {
            ...state,
            lockWinnerSuccess: action.payload
          };
 

    //   case actionType.WITHDRAW_FAILED:
    //   return {
    //     ...state,
    //     data: action.payload,
    //     withdrawFailed: true,
    //   };

    case "PONTS_CLEAR":
      return {
        ...state,
        status: null,
        error: null,
        isUpdate: null,
      };
  }
  return state;
};
