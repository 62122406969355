import * as actionType from './category.type'
import axios from 'axios'

export const addCategory = () => ({
    type: actionType.ADD_CATEGORY
})
export const updateCategory = (id) => ({
    type: actionType.UPDATE_CATEGORY,
    payload: id
})
export const categoryLoading = () => ({
    type: actionType.ADD_SAVING_START
})
export const categoryAdded = (data) => ({
    type: actionType.ADD_SAVING_SUCCESS,
    payload: data
})
export const categoryList = (data) => ({
    type: actionType.ADD_GETING_SUCCESS,
    payload: data
})
export const categoryFailed = () => ({
    type: actionType.ADD_SAVING_FAILED
})


export const addingCategoryAction = (data) => {
    const token = localStorage.getItem('token')

    return dispatch => {
        dispatch(categoryLoading())
        axios.post('/category', data,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(categoryAdded('Category saved Successfully'))
                dispatch(getingCategoryAction())
            }).catch((error) => {
                console.log(error)
                dispatch(categoryFailed())
            })
    }

}

export const updateCategoryAction = (data, id) => {
    const token = localStorage.getItem('token')
    return dispatch => {
        dispatch(categoryLoading())
        axios.patch('/category/' + id, data,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(categoryAdded('Category saved Successfully'))
                dispatch(getingCategoryAction())
            }).catch((error) => {
                console.log(error)
                dispatch(categoryFailed())
            })
    }

}

export const switchCategoryAction = (data, id) => {
    const token = localStorage.getItem('token')
    console.log(data)
    return dispatch => {
        dispatch(categoryLoading())
        axios.patch('/category/' + id, data,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(categoryAdded())
                dispatch(getingCategoryAction())
            }).catch((error) => {
                console.log(error)
                dispatch(categoryFailed())
            })
    }

}

export const deleteCategoryAction = (id) => {
    const token = localStorage.getItem('token')
    return dispatch => {
        dispatch(categoryLoading())
        axios.delete('/category/' + id,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(categoryAdded())
                dispatch(getingCategoryAction())
            }).catch((error) => {
                console.log(error)
                dispatch(categoryFailed())
            })
    }

}

export const getingCategoryAction = () => {
    const token = localStorage.getItem('token')
    return dispatch => {
        axios.get('/category',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)
                dispatch(categoryList(resp.data))
            }).catch((error) => {
                console.log(error.response)
                dispatch(categoryFailed())
            })
    }

}