export const ADD_QUESTION = 'ADD_QUESTION'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const QUESTION_SAVING_START = 'QUESTION_SAVING_START'
export const QUESTION_SAVING_SUCCESS = 'QUESTION_SAVING_SUCCESS'
export const QUESTION_SAVING_FAILED = 'QUESTION_SAVING_FAILED'
export const GET_QUESTION_LOADING = 'GET_QUESTION_LOADING'

export const QUESTION_GETING_START = 'QUESTION_GETING_START'
export const QUESTION_GETING_SUCCESS = 'QUESTION_GETING_SUCCESS'
export const QUESTION_GETING_FAILED = 'QUESTION_SAVING_FAILED'

export const BULK_UPLOAD = 'BULK_UPLOAD'

export const LOADING_FILE_SUCCESS = 'LOADING_FILE_SUCCESS'
export const LOADING_FILE_FAILED = 'LOADING_FILE_FAILED'
export const LOADING_FILE = 'LOADING_FILE'

