import React from "react";
import { Table } from "../../containers/Table/categoryTable.component";
import { useSelector } from "react-redux";
import { categoryListSelector } from "../../redux/category/category.selector";

export const Category = () => {
  const CategoryList = useSelector((state) => categoryListSelector(state));
  return (
    <Table
      data={CategoryList}
      link="/questions/"
      tableTitle="Category Table"
      label="Question List"
      
    />
  );
};
