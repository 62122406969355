import * as actionType from "./question.type";

const initialState = {
  isOpen: false,
  loading: false,
  data: [],
  error: null,
  status: null,
  isUpdate: null,
  isbulkUploadOpen: false,
  loadingFile: false,
  loadingFileSuccess: null,
  loadingFileError: false,
  getQuestionLoading: false,
};

export const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_QUESTION:
      return {
        ...state,
        isOpen: !state.isOpen,
        isUpdate: null,
        loading: false,
      };

    case actionType.BULK_UPLOAD:
      return {
        ...state,
        isbulkUploadOpen: !state.isbulkUploadOpen,
     
      };

           
      case actionType.LOADING_FILE:
         return {
           ...state,
           loadingFileSuccess: false,
           loadingFileError: false,
           loadingFile: true
         }

      case actionType.LOADING_FILE_SUCCESS:
         return {
           ...state,
           loadingFileSuccess: action.payload,
           loadingFileError: false,
           loadingFile: false
         }

         case actionType.LOADING_FILE_FAILED:
          return {
            ...state,
            loadingFileSuccess: false,
            loadingFileError: action.payload,
            loadingFile: false
          }


        return {
          ...state,
          isbulkUploadOpen: !state.isbulkUploadOpen,
          loadingFileSuccess: false,
          loadingFileError: null,
          loadingFile: true
        };

    case actionType.QUESTION_SAVING_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_QUESTION:
      return {
        ...state,
        isOpen: !state.isOpen,
        isUpdate: action.payload,
      };

    case actionType.QUESTION_GETING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
      };
    case actionType.QUESTION_SAVING_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionType.QUESTION_SAVING_SUCCESS:
      return {
        ...state,
        status: action.payload,
        loading: false,
      };
    case "QUESTION_CLEAR":
      return {
        ...state,
        status: null,
        error: null,
        isUpdate: null,
      };
      case "GET_QUESTION_LOADING":
        return {
          ...state,
          getQuestionLoading: !state.getQuestionLoading
        };
  }
  return state;
};
