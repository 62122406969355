export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const ADD_SAVING_START = 'ADD_SAVING_START'
export const ADD_SAVING_SUCCESS = 'ADD_SAVING_SUCCESS'
export const ADD_SAVING_FAILED = 'ADD_SAVING_FAILED'

export const ADD_GETING_START = 'ADD_GETING_START'
export const ADD_GETING_SUCCESS = 'ADD_GETING_SUCCESS'
export const ADD_GETING_FAILED = 'ADD_SAVING_FAILED'

