import * as actionType from "./user.type";
import axios from "axios";

export const setUsername = (data) => ({
  type: actionType.SET_USERNAME,
  payload: data,
});

export const setPassword = (data) => ({
  type: actionType.SET_PASSWORD,
  payload: data,
});

export const setToken = (data, usertype) => ({
  type: actionType.SET_TOKEN,
  payload: data,
  usertype: usertype
});

export const setLoginFailed = (data) => ({
  type: actionType.SET_LOGIN_FAILED,
  payload: data,
})

export const setLoading = () => ({
  type: actionType.SET_LOADING,
})

export const login = (username, password) => {
  console.log("Logging in....");

  console.log('username', username)

  return (dispatch) => {
    dispatch(setLoading())
    axios.post('/user/login', { username: username, password: password })
      .then((resp) => {
        if (resp.status == 200) {
          dispatch(setToken(resp.data.token, resp.data.userType))
          localStorage.setItem('token', resp.data.token)
          localStorage.setItem('type', resp.data.userType)
          dispatch(setLoading())
        }
      })
      .catch((error) => {
        console.log('error', error.response)
        if (error) {
          dispatch(setLoginFailed(error.response.data))
          dispatch(setUsername(''))
          dispatch(setPassword(''))
          dispatch(setLoading())
        }
      });
  };
};

export const AuthChecking = () => {
  console.log('AuthChecking')
  const token = localStorage.getItem('token')
  const type = localStorage.getItem('type')
  return dispatch => {
    if (token && type) {
      dispatch(setToken(token, type))
    } else {

      dispatch(logOut())
    }
  }
}

export const logOut = () => {
  console.log("Logging Out....");
  return dispatch => {
    dispatch(setToken(''))
    localStorage.clear()
  }
};
