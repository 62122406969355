import React, { useEffect, useState } from 'react'
import { Table } from '../../containers/Table/questionTable.component'
import { getingQuestionAction } from '../../redux/questions/question.action'
import { questionListSelector } from '../../redux/questions/question.selector'
import { useDispatch, useSelector } from 'react-redux';
// import { getingQuestionByIDAction } from '../../redux/questions/question.action'
export const Questions = ({ match }) => {
   const {getQuestionLoading} = useSelector(state => state.question)
    const { id } = match.params
    const Dispatch = useDispatch()

    const QuestionList = useSelector(state => questionListSelector(state))
    useEffect(() => {
        
        Dispatch(getingQuestionAction(id))
        
    }, [id])

    return (
        <Table loading={getQuestionLoading} data={QuestionList} tableTitle="Question Table" />
    )
}