import React, { useState } from "react";
import { Modal } from "../../components/UI/Modal.component";
import { useSelector, useDispatch } from "react-redux";
import {
  addQuestion,
  bulkUpload,
  uploadFile,
  loadingFileFailed,
  isloadingFileSuccess
} from "../../redux/questions/question.action";
import { FaDownload } from "react-icons/fa";
import { Spinner } from "../../components/UI/Spinner.component";

import { categoryListSelector } from "../../redux/category/category.selector";
import { questionUpdateSelector } from "../../redux/questions/question.selector";
import { Alert } from "../../components/UI/Alert.component";

export function BulkUpload() {
  const isUpdate = useSelector((state) => questionUpdateSelector(state));
  const CategoryLIst = useSelector((state) => categoryListSelector(state));
  const { loadingFileSuccess, loadingFileError, loadingFile } = useSelector(
    (state) => state.question
  );

  const [file, setfile] = useState("");
  const [quesCat, setquesCat] = useState("");
  const [quesType, setquesType] = useState('');

  const [fileErr, setfileErr] = useState()
  const [quesCatErr, setquesCatErr] = useState("");
  const [quesTypeErr, setquesTypeErr] = useState();


  const dispatch = useDispatch();
  const { isbulkUploadOpen } = useSelector((state) => state.question);

  React.useEffect(() => {
    setfile('')
    setquesCat('')
    setquesType('')
    setfileErr('')
    setquesCatErr('')
    setquesTypeErr('')
  },[])

  const submitHandler = (e) => {
    // setfile('')
    // setquesCat('')
    // setquesType('')
    setfileErr('')
    setquesCatErr('')
    setquesTypeErr('')

    if(quesCat == '' || !file|| quesType =='') {
            // alert('All felds are required')
           
            
            if(quesCat == '' ){
              setquesCatErr(true)
            }
            if( file == ''){
              setfileErr(true)
            
            }
            if( quesType ==''){
              setquesTypeErr(true)
            }
}else{
  let data = {
    m_id: quesCat,
    m_type: CategoryLIst.find((list) => list.cat_id == quesCat).cat_name,
    lang: quesType,
  };
 dispatch(uploadFile(data, file));
}
//  e.preventDefault();


 
   
  };
  const fileHandler = (e) => {
    console.log(e.target.files[0]);
    setfile(e.target.files[0]);
  };
  return (
    <>
      <Modal
        isopen={isbulkUploadOpen}
        title="Upload Questions"
        handleClose={() => {
          dispatch(bulkUpload())
          setfile('')
          setquesCat('')
          setquesType('')
          setfileErr('')
          setquesCatErr('')
          setquesTypeErr('')
        }
      }
        submit={submitHandler}
        btnDisabled={true}
      >
        <form>
          <div className="row mt-2">
            <div className="col-6">
              <select
                class="col-12 form-select form-select-sm mb-2"
                value={quesCat}
                onChange={(e) => setquesCat(e.target.value)}
                required
                disabled={isUpdate ? true : false}
              >
                <option defaultValue="">Select Question Category</option>
                {CategoryLIst.map((list, ind) => (
                  <option key={ind} value={list.cat_id}>
                    {list.cat_name}
                  </option>
                ))}
              </select>
             {quesCatErr && <span className="invalid">*This field is required</span>}
            </div>
            <div className="col-6">
              <select
                class="form-select form-select-sm"
                value={quesType}
                onChange={(e) => setquesType(e.target.value)}
                required
                disabled={isUpdate ? true : false}
              >
                <option defaultValue="">Select Language</option>
                <option value="eng">ENGLISH</option>
                <option value="french">FRENCH</option>
              </select>
              {quesTypeErr && <span className="invalid">*This field is required</span>}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <input
                type="file"
                // value={file}
                onChange={fileHandler}
                className="col-6"
                required
              ></input>
               {fileErr && <span className="invalid">*This field is required</span>}
            </div>
            <span className="text-center">
              <a href="#" className="btn get-file-format">
                Click here to get file format <FaDownload />
              </a>
            </span>
          </div>

          {/* <div className="col-1">
                  <i class="fa fa-plus-square add-cursor" onClick={() => Dispatch(addCategory())} aria-hidden="true"></i>
                </div> */}
        </form>
        <Alert
          isOpen={loadingFileError}
          close={() => dispatch(loadingFileFailed(false))}
          title={loadingFileError}
        ></Alert>
        <Alert
          isOpen={loadingFileSuccess}
          close={() => dispatch(isloadingFileSuccess(false))}
          title="FIle Uploaded Successfully"
        ></Alert>
      </Modal>
      <Spinner open={loadingFile}></Spinner>
    </>
  );
}
