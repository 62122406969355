import * as actionType from './winners.type'
import axios from 'axios'

export const gettingWinners = () => ({
    type: actionType.GETTING_WINNERS,
})

export const getWinnersSuccess = (data) => ({
    type: actionType.GET_WINNERS_SUCCESS,
    payload: data
})

export const getWinnersError = () => ({
    type: actionType.GET_WINNERS_ERROR,
})

export const getWinners = () => {
    const token = localStorage.getItem('token')
    return dispatch => {
     dispatch(gettingWinners())
        axios.get('/winnerlist', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp)   
               dispatch(getWinnersSuccess(resp.data))                        

            }).catch((error) => {
                console.log(error.response.data)
                dispatch(getWinnersError())  
            })
    }

}