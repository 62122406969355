import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components/UI/Modal.component";
import {
  addQuestion,
  addingQuestionAction,
  updateQuestionAction,
} from "../../redux/questions/question.action";
import { QuestionSelector } from "../../redux/questions/question.selector";
import {
  categoryListSelector,
  categorySelector,
} from "../../redux/category/category.selector";
import {
  questionMessageSelector,
  questionListSelector,
  questionUpdateSelector,
  questionLoadingSelector,
} from "../../redux/questions/question.selector";
import { addCategory } from "../../redux/category/category.action";
import { Spinner } from "../../components/UI/Spinner.component";
import { Alert } from "../../components/UI/Alert.component";



export const AddQuestion = () => {
  const [quesCat, setquesCat] = useState("");
  const [quesCatErr, setquesCatErr] = useState("");

  const [quesType, setquesType] = useState("");
  const [quesTypeErr, setquesTypeErr] = useState("");

  const [question, setQuestion] = useState("");
  const [questionErr, setquestionErr] = useState("");

  const [optionA, setOptionA] = useState("");
  const [optionAErr, setoptionAErr] = useState("");

  const [optionB, setOptionB] = useState("");
  const [optionBErr, setOptionBErr] = useState("");

  const [currectOption, setCurrectOption] = useState("");
  const [currectOptionErr, setCurrectOptionErr] = useState("");
  const [isWordInLimit, setIsWordInLimit] = useState(true);

  const Dispatch = useDispatch();
  const isOpenQuestion = useSelector((state) => QuestionSelector(state));
  const isOpenCategory = useSelector((state) => categorySelector(state));
  const CategoryLIst = useSelector((state) => categoryListSelector(state));
  const questionRespMsg = useSelector((state) =>
    questionMessageSelector(state)
  );
  const isUpdate = useSelector((state) => questionUpdateSelector(state));
  const QuestionList = useSelector((state) => questionListSelector(state));
  const Loading = useSelector((state) => questionLoadingSelector(state));

  let unSelectedclass = "badge bg-secondary option-hover p-2";
  const selectedClass = "badge bg-warning text-dark option-hover p-2";

  useEffect(() => {
    if (isUpdate) {
      console.log(isUpdate);
      let Array = QuestionList.find((list) =>
        list.m_id == isUpdate.m_id &&
        list.q_id == isUpdate.q_id &&
        list.lang == isUpdate.lang
      );
      console.log("Array", Array);
      setquesCat(Array.m_id);
      setquesType(Array.lang)
      setQuestion(Array.message);
      setCurrectOption(Array.c_opt);
    } else {
      setquesCat("");
      setquesType("");
      setQuestion("");
      setCurrectOption("");
    }
  }, [isUpdate]);

  useEffect(() => {
    setquesCat("");
  }, []);

  const saveHandler = () => {
    setquesCatErr("");
    setquesTypeErr("");
    setquestionErr("");
    setoptionAErr("");
    setOptionBErr("");
    setCurrectOptionErr("");
    setquesTypeErr("")
    if (quesCat && question && currectOption, quesType) {
      let data = {
        m_id: quesCat,
        lang: quesType,
        message: question,
        c_opt: currectOption,
        m_type: CategoryLIst.find((list) => list.cat_id == quesCat).cat_name
      };

      if (!isUpdate) {
        Dispatch(addingQuestionAction(data));
        setquesCat("");
        setquesType("");
        setQuestion("");
        setOptionA("");
        setOptionB("");
        setCurrectOption("");
      } else {
        Dispatch(updateQuestionAction(data, isUpdate));
      }
    } else {
      if (quesCat == "") {
        setquesCatErr("fsf");
      }
      if (quesType == "") {
        setquesTypeErr("fsf");
      }
      if (question == "") {
        setquestionErr("fsf");
      }
      if (optionA == "") {
        setoptionAErr("fsf");
      }
      if (optionB == "") {
        setOptionBErr("fsf");
      }
      if (currectOption == "") {
        setCurrectOptionErr("fsf");
      }
    }
  };
  const HandleClose = () => {
    Dispatch({ type: "QUESTION_CLEAR" });
    Dispatch(addQuestion());
    setquesCat("");
  };

  let wordCount = null;

  if (question || optionA || optionB) {
    // setIsWordInLimit(true)

    let QusLength = question ? question.length : 0;
    let TotalCount = QusLength
    if (TotalCount > 160 && isWordInLimit) {
      setIsWordInLimit(false);
    }
    if (TotalCount < 161 && !isWordInLimit) {
      setIsWordInLimit(true);
    }
    wordCount = (
      <span style={{ color: TotalCount < 161 ? "green" : "red" }}>
        Word Count is {TotalCount}
      </span>
    );
  }
  return !questionRespMsg ? (
    <>
      {!isOpenCategory ? (
        <>
          <Modal
            isopen={isOpenQuestion}
            title="Add Question"
            btnDisabled={isWordInLimit}
            handleClose={() => {
              setquesCat('')
              setQuestion('')
              setOptionA('')
              setOptionB('')
              setCurrectOption('')

              setquesCatErr("");
              setquesCatErr("");
              setquestionErr("");
              setoptionAErr("");
              setOptionBErr("");
              setquesTypeErr("")
              setCurrectOptionErr("");

              Dispatch(addQuestion())

            }}
            submit={saveHandler}
          >
            <div>
            {/* <span className="btn"><FaDownload/></span>
            <div className="row">
         
                <div className="col">
                  <div className="text-center">
                  
                    <form>
                   
                    <input type="file" required></input><button className="btn btn-primary" type="submit">Upload</button>
                    </form>
                  </div>
                </div>
             </div> */}
              <div className="row" >
                {/* <hr className="mt-3"/>
              <h6 className="text-center mb-4">OR</h6>
              <hr className="mb-3"/> */}
                <div className="col-12" >
                  <select
                    class="col-12 form-select form-select-sm mb-2"
                    value={quesCat}
                    onChange={(e) => setquesCat(e.target.value)}
                    required
                    disabled={isUpdate ? true : false}
                  >
                    <option defaultValue="">Select Question Category</option>
                    {CategoryLIst.map((list, ind) => (
                      <option key={ind} value={list.cat_id}>
                        {list.cat_name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="col-1">
                  <i class="fa fa-plus-square add-cursor" onClick={() => Dispatch(addCategory())} aria-hidden="true"></i>
                </div> */}
              </div>
              {quesCatErr && (
                <span class="invalid">* Category is required</span>
              )}

              <div className="mb-3">
                <label for="question" className="form-label">
                  Question
                </label>
                <textarea class="form-control"
                  placeholder="Write your Question Here"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  id="floatingTextarea2"
                  maxLength="160"
                  rows="4"
                  style={{ height: "120px" }}
                >

                </textarea>
                {questionErr && (
                  <span class="invalid">* Question is required</span>
                )}
              </div>
              <div className="row g-3 ">

                <div className="row mt-3">
                  <div className="col-2">
                    <span className="form-label text-right">Answer</span>
                  </div>
                  <div className="col-5 d-flex justify-content-around">
                    <span
                      className={
                        currectOption == "1" ? selectedClass : unSelectedclass
                      }
                      onClick={() => setCurrectOption("1")}
                    >
                      Option 1
                    </span>
                    <span
                      className={
                        currectOption == "2" ? selectedClass : unSelectedclass
                      }
                      onClick={() => setCurrectOption("2")}
                    >
                      Option 2
                    </span>
                  </div>

                  <div className="col-5" >
                    <select
                      class="form-select form-select-sm"
                      value={quesType}
                      onChange={(e) => setquesType(e.target.value)}
                      required
                      disabled={isUpdate ? true : false}
                    >
                      <option defaultValue="">Select Language</option>
                      <option value="eng">ENGLISH</option>
                      <option value="french">FRENCH</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      {currectOptionErr && (
                        <span class="invalid text-center">
                          * Answer is required
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      {quesTypeErr && (
                        <span class="invalid">*Language is required</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>{wordCount}</div>

            
            </div>

          </Modal>
          <Spinner open={Loading} />
        </>
      ) : null}
    </>
  ) : (
      <Alert
        title={questionRespMsg}
        isOpen={isOpenQuestion}
        close={HandleClose}
      />
    );
};
