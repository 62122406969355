import * as actionType from './prizes.type'

const initialState = {
    loading: false,
    data: [],
    error: null
}


export const prizeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.PRIZES_LIST_LOADING_START:
            return {
                ...state,
                loading: true
            }
        case actionType.PRIZES_LIST_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case actionType.PRIZES_LIST_LOADING_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
    }
    return state
}