import React from 'react'
import { BackDrop } from './BackDrop.component'
export const Spinner = ({ type, open }) => {
    if (type == '1') {
        return <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    } else {
        return <BackDrop open={open}  background={true}>
            <div class="spinner-border text-primary" role="status">
                {/* <span class="sr-only"></span> */}
            </div>
        </BackDrop>
    }

}