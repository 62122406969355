import React,{useState} from 'react';
import DataTable,{createTheme} from 'react-data-table-component'
// import Button from '../shared/Button'
import { Switch } from '../../components/UI/Switch.component'
import { Link } from 'react-router-dom'
import { updateCategory, deleteCategoryAction, switchCategoryAction } from '../../redux/category/category.action'
import { useDispatch } from 'react-redux'
import { Alert } from '../../components/UI/Alert.component'

const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            // border: '1px solid',
            // borderRadius: '10px',
            justifyContent: 'center',
            fontWeight: 800,
            textTransform: 'uppercase',
            paddingBottom: '20px',
            marginTop: '30px'
            // height: '25px'
            
        },
    },
    cells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for data cells
            // paddingRight: '8px',
            justifyContent: 'center',
            fontWeight: 600,
            padding: '4px',


        },
    },
    title: {
        style: {
            fontSize: '40px'
        }
    }

};


createTheme('solarized', {
    text: {
    //   primary: '#268bd2',
    //   secondary: '#2aa198',
    },
    background: {
    //   default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#ccd6d8',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  });

export const Table = ({ data, link, label, tableTitle }) => {
    const [isDelete, setIsDelete] = useState(false)
    const [rowId, setRowId] = useState('')
    let columns = []
    let Col_Array = data.map((list) => Object.keys(list))
    const Dispatch = useDispatch()

    const EnableHandler = (id, enable) => {
        let data = {
            is_enable: !enable
        }
        Dispatch(switchCategoryAction(data, id))
    }

    const deleteHandler = (id) => {
        setIsDelete(true)
        setRowId(id)
    }

    if (Col_Array.length > 0) {
        Col_Array[0].map(list => {
            if (list == 'id' || list == 'is_enable') {

            } else {
                columns.push({
                    name: list.toUpperCase(),
                    selector: list
                })
            }
        })
    }
    columns.push(
    //     {
    //     name: 'enable',
    //     selector: 'is_enable',
    //     cell: row => <Switch isChecked={row.is_enable} onChange={() => EnableHandler(row.cat_id, row.is_enable)} />
    // },
    //  {
    //     name: 'Edit',
    //     cell: row => <button type="button" onClick={() => Dispatch(updateCategory(row.cat_id))} class="btn btn-warning">Edit</button>
    // },
    //     {
    //         name: 'Delete',
    //         cell: row => <button type="button" onClick={() => deleteHandler(row.cat_id)} class="btn btn-danger">Delete</button>
    //     },

    )
    if (link) {
        columns.push(
            {
                name: 'View',
                cell: row => <Link type="button" to={`${link}${row.cat_id}`} class="btn btn-success">{label}</Link>
            }
        )
    }
    return (
        <>
            {/* <Button onClick={() => setHideDirector(!hideDirector)}>Hide Directory Column</Button> */}
            <DataTable
                title={tableTitle}
                columns={columns}
                data={data}
                pagination={true}
                customStyles={customStyles}
                responsive={true}
                dense={true}
                theme= 'solarized'

            />
             {isDelete ? <Alert
                title="Do you want to delete Category"
                type="confirm"
                isOpen={isDelete}
                close={() => setIsDelete(!isDelete)}
                confirm={() => {
                    Dispatch(deleteCategoryAction(rowId))
                    setIsDelete(!isDelete)
                }}
            />
                : null
            }
        </>
    );
};

