import React from 'react'
import { BackDrop } from './BackDrop.component'

export const Alert = ({ isOpen, close, confirm, title, type }) => {
    let btnContainer = null
    if (type === 'confirm') {
        btnContainer = (<>
            {close ? <button type="button" class="btn btn-danger" onClick={close} >Cancel</button> : null}
            {confirm ? <button type="button" class="btn btn-success" onClick={confirm}   >Confirm</button> : null}
        </>)
    } else {
        btnContainer = <button type="button" class="btn btn-warning" onClick={close}   >OK</button>
    }
    return (
        <BackDrop open={isOpen} background={true}  >
            <div className="modal-wrap">
                <div class="modal-dialog modal-dialog-centered jusctify-content-center">
                    <div class="modal-content">
                        <div className="col d-flex flex-column p-3">
                            <h6 className="text-center " >{title}</h6>
                            <div className="d-flex flex-row justify-content-around mt-4">
                                {btnContainer}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BackDrop>

    )
}
