import {createSelector} from 'reselect'


const Point = (state) => state.point 


export const pointLoadingSelector = createSelector(
    [Point],
    (data) => data.loading
)


export const pointListSelector = createSelector(
    [Point],
    (data) => data.data
)

export const pointMessageSelector = createSelector(
    [Point],
    (data) => data.status || data.error
)

