import React, { useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component'
// import Button from '../shared/Button'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            justifyContent: 'center',
            fontWeight: 800,
            textTransform: 'uppercase',
            paddingBottom: '20px',
            marginTop: '30px'
            // height: '25px'

        },
    },
    cells: {
        style: {
            justifyContent: 'center',
            fontWeight: 600,
            padding: '4px',
            textAlign: 'center'


        },
    },
    title: {
        style: {
            fontSize: '40px'
        }
    }

};


createTheme('solarized', {
    text: {
        //   primary: '#268bd2',
        //   secondary: '#2aa198',
    },
    background: {
        //   default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#ccd6d8',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
});



export const Table = ({ data, link, label, tableTitle, loading }) => {
  console.log('TABLE DATA',data);
  let columns = []
  let Col_Array = data.map((list) => Object.keys(list))

   console.log('Col_Array',Col_Array);

   if (Col_Array.length > 0) {
    Col_Array[0].map(list => {
        if (list == 'ani') {
            columns.push({
                name: list.toUpperCase(),
                selector: list,
                wrap: true
            })
        } else {
            columns.push({
                name: list.toUpperCase(),
                selector: list
            })
        }
    })
    }
    console.log('COLUMNS',columns);

    function convertArrayOfObjectsToCSV(array) {
        let result;
      
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
    
        let headings = columns.map(item =>{
            return item.name
        });
        
        console.log('HEADINGS', headings);

        const keys = columns;
    //   let headings = ['DATE','New Active Sub/rs','Daily Cancellations','D'];
        result = '';
        result += headings.join(columnDelimiter);
        result += lineDelimiter;
      
        array.forEach(item => {
          let ctr = 0;
          keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
      
            result += item[key.selector];
            
            ctr++;
          });
          result += lineDelimiter;
        });
      
        return result;
      }
      
      // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
      function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
      
        const filename = 'winners.csv';
      
        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }
      
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
      }
      
      
      const Export = ({ onExport }) => (
        <button className="export-btn btn btn-dark" onClick={e => onExport(e.target.value)}>Export</button>
      );
      
    
      const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, [data]);
   

  
    return (
        <>
            <DataTable
                progressPending={loading}
                title={tableTitle}
                columns={columns}
                data={data}
                pagination={true}
                customStyles={customStyles}
                responsive={true}
                dense={true}
                theme='solarized'
                actions={actionsMemo}
            />
        </>
    );
};

