import React from "react";

export const Modal = ({
  isopen,
  children,
  title,
  btnDisabled,
  handleClose,
  submit,
}) => {
  let modelId = Math.random();

  const handleSubmit = (e) => {

    submit();
        e.preventDefault();
  };

  return isopen ? (
    <form onSubmit={handleSubmit}>
      <div className="modal-wrap">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={
                    ()=>{
                        handleClose()
                        // setquesCat('')
                    }
                   
                }
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">{children}</div>
            <div class="modal-footer">
              <button
                type="button"
                onClick={() => handleClose()}
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {submit ? (
                <button
                 
                  class="btn btn-primary"
                  disabled={!btnDisabled}
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
     </form>
  ) : null;
};
