import React from 'react'

export const BackDrop = ({ children, open, click, background }) => {
    let classes = 'back-drop'
    if (background) {
        classes = 'back-drop back-drop-bg'
    }
    console.log(classes)
    return (
        open ?
            <div className={classes} onClick={click} >
                {children}
            </div>
            :
            null
    )
}