import * as actionType from './prizes.type'
import axios from 'axios'
const prizeLodingStart = () => ({
    type: actionType.PRIZES_LIST_LOADING_START
})


const prizeLodingSuccess = (data) => ({
    type: actionType.PRIZES_LIST_LOADING_SUCCESS,
    payload : data
})


const prizeLodingFailed = () => ({
    type: actionType.PRIZES_LIST_LOADING_FAILED
})

export const fetchPrizeList = () => {
    const token = localStorage.getItem('token')
    return dispatch => {
        axios.get('/prizes', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log('PRIZES',resp);
                dispatch(prizeLodingSuccess(resp.data))
            }).catch((error) => {
                console.log(error.response)
                dispatch(prizeLodingFailed())
            })
    }
}