import * as actionType from './category.type'

const initialState = {
    isOpen: false,
    loading: false,
    data: [],
    error: null,
    status: null,
    isUpdate: null
}


export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_CATEGORY:
            return {
                ...state,
                isOpen: !state.isOpen,
                error: null,
                status: null,
                loading: false,
                isUpdate: null
            }
        case actionType.ADD_SAVING_START:
            return {
                ...state,
                error: null,
                status: null,
                loading: true,
                isUpdate: null
            }
        case actionType.UPDATE_CATEGORY:
            return {
                ...state,
                isOpen: !state.isOpen,
                error: null,
                status: null,
                loading: false,
                isUpdate: action.payload
            }
        case actionType.ADD_GETING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case actionType.ADD_SAVING_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case actionType.ADD_SAVING_SUCCESS:
            return {
                ...state,
                status: action.payload,
                loading: false,

            }
        case 'ADD_CLEAR':
            return {
                ...state,
                status: null,
                error: null,
                isUpdate: null
            }
    }
    return state
}