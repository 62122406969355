import { combineReducers } from 'redux';
import {questionReducer} from './questions/question.reducer'
import {categoryReducer} from './category/category.reducer'
import {userReducer} from './user/user.reducer'
import {pointReducer} from './userPoints/user.points.reducer'
import {prizeReducer} from './prizes/prizes.reducer'
import {reportsReducer} from './reports/reports.reducer'
import { winnersReducer } from './winners/winners.reducer';


const rootReducer = combineReducers({
    question : questionReducer,
    category : categoryReducer,
    user: userReducer,
    point : pointReducer,
    prize : prizeReducer,
    reports: reportsReducer,
    winners: winnersReducer

}) 

export default rootReducer
