import axios from 'axios';
import * as actionType from './reports.type'


export const setReports = (data) => ({
    type: actionType.SET_REPORTS,
    payload: data
})

export const setLoading = (data) => ({
    type: actionType.SET_LOADING,
    payload: data
})

export const getReports = (token) => {
console.log('GEttING Reports...');
    return dispatch => {
        dispatch(setLoading())
        axios.get('/report',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                resp.data.map(data => {
                    // console.log('DATATA',data);
                  
                    var date = new Date(data.created_on);
                    var yesterday = new Date(date.getTime() - 24*60*60*1000);
                    console.log('Yesterday',yesterday.toISOString().slice(0,10));
                    data.created_on = yesterday.toISOString().slice(0,10)
                })
                dispatch(setLoading())
                // console.log('NEWDATA',newData);
                dispatch(setReports(resp.data))
                
            }).catch((error) => {
                dispatch(setLoading())
                console.log(error)
                
            })
    }

   
}