import React,{useState} from 'react';
import DataTable,{createTheme} from 'react-data-table-component'
// import Button from '../shared/Button'
import { Switch } from '../../components/UI/Switch.component'
import { Link } from 'react-router-dom'
import { updateCategory, deleteCategoryAction, switchCategoryAction } from '../../redux/category/category.action'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '../../components/UI/Alert.component'
// import DataTableExtensions from 'react-data-table-component-extensions';
// import 'react-data-table-component-extensions/dist/index.css';
// import Button from '../shared/Button';


const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            // border: '1px solid',
            // borderRadius: '10px',
            justifyContent: 'center',
            // fontWeight: 800,
            textAlign: 'center',
            textTransform: 'uppercase',
            paddingBottom: '20px',
            marginTop: '30px'
            // height: '25px'
            
        },
    },
    cells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for data cells
            // paddingRight: '8px',
            justifyContent: 'center',
            // fontWeight: 600,
            padding: '4px',


        },
    },
    title: {
        style: {
            // fontSize: '40px'
        }
    }

};


createTheme('solarized', {
    text: {
    //   primary: '#268bd2',
    //   secondary: '#2aa198',
    },
    background: {
    //   default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#ccd6d8',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  });

export const Table = ({ data, link, label, tableTitle }) => {
    const [isDelete, setIsDelete] = useState(false)
    const [rowId, setRowId] = useState('')
    let columns = []
    let Col_Array = data.map((list) => Object.keys(list))
    const Dispatch = useDispatch()
const [loading, setloading] = useState(true)
React.useEffect(() => {
    setTimeout(() => {
        setloading(false)
    }, 2000);


    // data.map(data => {
    //     console.log('DATATA',data);
      
    //     var date = new Date(data.created_on);
    //     var yesterday = new Date(date.getTime() - 24*60*60*1000);
    //     console.log('Yesterday',yesterday.toISOString().slice(0,10));
    //     data.created_on = yesterday.toISOString().slice(0,10)
    // })
 
}, [data])

    // if (Col_Array.length > 0) {
    //     Col_Array[0].map(list => {
    //         if (list == 'id' || list == 'is_enable') {

    //         } else {
    //             columns.push({
    //                 name: list.toUpperCase(),
    //                 selector: list
    //             })
    //         }
    //     })
    // }

  
    columns = [
        {
            name: 'DATE',
            selector: 'created_on',
        },
        {
            name: 'New Active Sub/rs',
            selector: 'new_active_sub',
        },
        {
            name: 'Daily Cancellations',
            selector: 'daliy_cancel',
        },
        {
            name: 'Active Subscriptions',
            selector: 'active_sub',
        },
        {
            name: 'Sub/rs free Conten',
            selector: 'sub_fee',
        },
        {
            name: 'DAcross selling users (free to premium)TE',
            selector: 'cross_Sell',
        },
        {
            name: 'Sub/rs Premium Content',
            selector: 'sub_prem',
        },
        {
            name: 'Daily Sub/rs Charged',
            selector: 'daily_sub',
        },
        {
            name: 'Repeat Sub/rs Charged',
            selector: 'repeat_charge',
        },
        {
            name: 'Nbr of user billied at 200 XAF',
            selector: 'billed',
        },
        {
            name: 'Total Revenues',
            selector: 'total',
        },

    ]

    function convertArrayOfObjectsToCSV(array) {
        let result;
      
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
    
        let headings = columns.map(item =>{
            return item.name
        });
        const keys = columns;
    //   let headings = ['DATE','New Active Sub/rs','Daily Cancellations','D'];
        result = '';
        result += headings.join(columnDelimiter);
        result += lineDelimiter;
      
        array.forEach(item => {
            console.log("ITEM", item);
          let ctr = 0;
          keys.forEach(key => {
              
            if (ctr > 0) result += columnDelimiter;
      
            result += item[key.selector];
            
            ctr++;
          });
          result += lineDelimiter;
        });
      
        return result;
      }
      
      // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
      function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
      
        const filename = 'report.csv';
      
        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }
      
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
      }
      
      
      const Export = ({ onExport }) => (
        <button className="export-btn btn btn-dark" onClick={e => onExport(e.target.value)}>Export</button>
      );
      
    
      const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, [data]);

  
    return (
        <>
           
   
            <DataTable
                title={tableTitle}
                columns={columns}
                data={data}
                pagination={true}
                customStyles={customStyles}
                responsive={true}
                dense={true}
                theme= 'solarized'
                actions={actionsMemo}
                progressPending={loading}
            />
      
           
        </>
    );
};

