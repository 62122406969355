import React,{useState,useEffect} from 'react'
import { Table } from "../../containers/Table/winnersTable";
import { useDispatch, useSelector } from 'react-redux';
import { getWinners } from '../../redux/winners/winners.action';
import {Link} from 'react-router-dom'
import {IoMdArrowRoundBack} from 'react-icons/io'

export function Winners() {

    const dispatch = useDispatch()
    const {winners,winnersLoading} = useSelector(state => state.winners)
    
    useEffect(() => {
     dispatch(getWinners())
        // console.log('hi');
        //  console.log(token);
        //  setTimeout(() => {
        //      setloading(false)
        //  }, 1000);
  
    },[])
    console.log(winners);
    return (
        <div>
           <div className="btn d-flex justify-content-start"> <button className="back-btn"><Link to="/"><IoMdArrowRoundBack/></Link></button></div>
         <Table tableTitle="Winners" data ={winners} loading={winnersLoading} />
        </div>
    )
}

