import React, { useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component'
// import Button from '../shared/Button'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            justifyContent: 'center',
            fontWeight: 800,
            textTransform: 'uppercase',
            paddingBottom: '20px',
            marginTop: '30px'
            // height: '25px'

        },
    },
    cells: {
        style: {
            justifyContent: 'center',
            fontWeight: 600,
            padding: '4px',
            textAlign: 'center'


        },
    },
    title: {
        style: {
            fontSize: '40px'
        }
    }

};


createTheme('solarized', {
    text: {
        //   primary: '#268bd2',
        //   secondary: '#2aa198',
    },
    background: {
        //   default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#ccd6d8',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
});



export const Table = ({ data, link, label, tableTitle, loading }) => {
    const [isDelete, setIsDelete] = useState(false)
    const [rowId, setRowId] = useState('')
    let columns = []
    let Col_Array = data.length !== 0 && data.map((list) => Object.keys(list))
    const Dispatch = useDispatch()


    if (Col_Array.length > 0) {
        Col_Array[0].map(list => {
            if (list == 'id' || list == 'is_enable') {

            } else {
                columns.push({
                    name: list.toUpperCase(),
                    selector: list,
                    wrap: true
                })
            }
        })
    }

    if (link) {
        columns.push(
            {
                name: 'View',
                cell: row => <Link type="button" to={`${link}${row.cat_id}`} class="btn btn-success">{label}</Link>
            }
        )
    }
    return (
        <>
            <DataTable
                progressPending={loading}
                title={tableTitle}
                columns={columns}
                data={data}
                pagination={true}
                customStyles={customStyles}
                responsive={true}
                dense={true}
                theme='solarized'
            />
        </>
    );
};

