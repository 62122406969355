import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addQuestion, bulkUpload } from "../../redux/questions/question.action";
import {
  addCategory,
  addingCategoryAction,
  updateCategoryAction,
} from "../../redux/category/category.action";
import { logOut } from "../../redux/user/user.action";
import { userPointList } from "../../redux/userPoints/user.points.action";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { GrLogout, IconName } from "react-icons/gr";
import {useHistory } from 'react-router-dom'

export const Header = () => {
  const Dispatch = useDispatch();
  const { usertype } = useSelector((state) => state.user);
   const history = useHistory()
  return (
    <nav className="navbar navbar-dark ">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src={Logo}
            alt=""
            width="30"
            height="24"
            className="d-inline-block align-bottom logo"
          />
         {usertype == '0' && 'Camroon Winner Withdraw'}
         {usertype == '2' && 'Camroon MIS Report'}
         {usertype == '1' && 'Camroon Question Portal'}
        </Link>
        <div>
          {usertype == 1 ? (
            <>
              {" "}
              <button
                type="button"
                className="btn nav-btn button"
                onClick={() => Dispatch(addQuestion())}
              >
                Add Question
              </button>
              <button
                type="button"
                className="btn nav-btn button"
                onClick={() => Dispatch(bulkUpload())}
              >
                Bulk Uplod
              </button>
            </>
          ) : null}
          {/* <button type="button" className="btn nav-btn" onClick={() => Dispatch(addCategory())}>Add Category</button> */}
          {usertype == 0 ? (
            <>
              {" "}
              <button
                type="button"
                className="btn nav-btn button"
              >
                <Link to="/winners" style={{color: '#fff', textDecoration: 'none'}}>Winners</Link>
              </button>
            </>
          ) : null}
         <button
            type="button"
            className="btn logout-btn"
            onClick={() => 
                {
                  Dispatch(userPointList([]))
                    Dispatch(logOut())
                    history.push('/')
                }
            }
          >
            <GrLogout className="logoutIcon" />
          </button>
        </div>
      </div>
    </nav>
  );
};
