import React,{useState,useEffect} from 'react'
import { Table } from "../../containers/Table/reportsTable";
import { useDispatch, useSelector } from 'react-redux';
import { getReports } from '../../redux/reports/reports.action'

function Report() {
    const dispatch = useDispatch()
    const {reports} = useSelector(state => state.reports)
    const {token} = useSelector(state => state.user)
    const {loading} = useSelector(state => state.reports)
    // const [loading, setloading] = useState(true)
    
    useEffect(() => {
        // console.log('hi');
         dispatch(getReports(token));
        //  console.log(token);
        //  setTimeout(() => {
        //      setloading(false)
        //  }, 1000);
    },[])

    return (
        <div>
         <Table tableTitle="Reports Table" data = {reports} loading={loading}/>
         
        </div>
    )
}

export default Report
