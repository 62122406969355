import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { Switch } from '../../components/UI/Switch.component'
import { Link } from 'react-router-dom'
import { updateQuestion, deleteQuestionAction, switchQuestionAction } from '../../redux/questions/question.action'
import { useDispatch } from 'react-redux';
import { Alert } from '../../components/UI/Alert.component'
const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
            padding: '4px'
        }
    },
    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            border: '1px solid',
            borderRadius: '10px',
            justifyContent: 'center',
            // width: '100%'
            // height: '25px'

        },
    },
    cells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for data cells
            // paddingRight: '8px',
            // justifyContent: 'center',
            // fontWeight: 600
            justifyContent: 'center',
            // display: 'flex',
            fontWeight: 600,
            // padding: '4px',

        },
    },
};



const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField id="search" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
        <button type="button" className="btn btn-primary clear-btn" onClick={onClear}>X</button>
    </>
);


export const Table = ({ data, link, label, tableTitle, loading }) => {
    const Dispatch = useDispatch()
    const [isDelete, setIsDelete] = useState(false)
    const [rowId, setRowId] = useState('')
    const [language, setLanguage] = useState('')
    const [tableData, setTableData] = useState([])
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    useEffect(() => {
        if (language && data.length > 0) {
            let Array = data.filter((list)=>list.lang == language)
            setTableData(Array)
        }
        if(!language && data.length > 0){
            setTableData(data)
        }
    }, [language, data])


    const filteredItems = tableData.filter(item =>
        item.q_id.toString().includes(filterText.toLowerCase()) ||
        item.m_type.toLowerCase().includes(filterText.toLowerCase()) ||
        item.message.toLowerCase().includes(filterText.toLowerCase())
    );


    const EnableHandler = (id, enable) => {
        let data = {
            is_enable: !enable
        }
        Dispatch(switchQuestionAction(data, id))
    }

    const deleteHandler = (id) => {
        setIsDelete(true)
        setRowId(id)
    }

    let columns = [
        {
            name: 'Question ID',
            selector: 'q_id',
        },
        {
            name: 'Question Type',
            selector: 'm_type',
        },
        {
            name: 'Question',
            // selector: 'message',
            grow: 4,
            cell: row => <div className="text-center">
                {row.message}
            </div>
        },
        {
            name: 'Correct Option',
            selector: 'c_opt',
        },
        {
            name: 'Language',
            selector: 'lang',
            sortable: true
        },
    ]   

    columns.push(
        //     {
        //     name: 'enable',
        //     selector: 'is_enable',
        //     cell: row => <Switch isChecked={row.is_enable} onChange={() => EnableHandler(row.id, row.is_enable)} />
        // },
        {
            name: 'Edit',
            cell: row => <button type="button" onClick={() => Dispatch(updateQuestion(row))} class="btn btn-warning">Edit</button>
        },
        {
            name: 'Delete',
            cell: row => <button type="button" onClick={() => deleteHandler(row)} class="btn btn-danger">Delete</button>
        },

    )

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <>
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            <select class="select-lan-tbl" value={language} onChange={(e) => setLanguage(e.target.value)} >
                <option defaultValue="" >Filter with Language</option>
                <option value="eng" >English</option>
                <option value="french" >French</option>
            </select>

        </>
            ;
    }, [filterText, resetPaginationToggle]);



    return (
        <>
            {/* <Button onClick={() => setHideDirector(!hideDirector)}>Hide Directory Column</Button> */}
            <DataTable
                title={tableTitle}
                columns={columns}
                data={filteredItems}
                pagination={true}
                responsive={true}
                customStyles={customStyles}
                dense={true}
                progressPending={loading}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
            />
            {isDelete ? <Alert
                title="Do you want to delete Question"
                type="confirm"
                isOpen={isDelete}
                close={() => setIsDelete(!isDelete)}
                confirm={() => {
                    Dispatch(deleteQuestionAction(rowId))
                    setIsDelete(!isDelete)
                }}
            />
                : null
            }
        </>
    );
};

