import {createSelector} from 'reselect'

const Question = (state) => state.question 

export const QuestionSelector = createSelector(
    [Question],
    (data) => data.isOpen
)

export const questionLoadingSelector = createSelector(
    [Question],
    (data) => data.loading
)

export const questionListSelector = createSelector(
    [Question],
    (data) => data.data
)

export const questionUpdateSelector = createSelector(
    [Question],
    (data) => data.isUpdate
)

export const questionMessageSelector = createSelector(
    [Question],
    (data) => data.status || data.error
)

