import {createSelector} from 'reselect'


const Category = (state) => state.category 

export const categorySelector = createSelector(
    [Category],
    (data) => data.isOpen
)

export const categoryLoadingSelector = createSelector(
    [Category],
    (data) => data.loading
)

export const categoryUpdateSelector = createSelector(
    [Category],
    (data) => data.isUpdate
)

export const categoryListSelector = createSelector(
    [Category],
    (data) => data.data
)

export const categoryMessageSelector = createSelector(
    [Category],
    (data) => data.status || data.error
)

